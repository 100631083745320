<template>
    <div>
        <div class="container">
            <el-input v-model="query2" placeholder="空格隔开筛选项" clearable class="handle-input mr10" style="float:left;margin-bottom:20px;display:block;width:240px;"></el-input>
            
            <el-table
                :data="search(query2).slice((currentPage1-1)*pagesize,currentPage1*pagesize)"
                border
                height="550"
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @sort-change="sortChange"
            >
                <el-table-column prop="id" sortable="custom" label="id" width="120"></el-table-column>
                <el-table-column prop="app_id" sortable="custom" label="app_id"></el-table-column>
                <el-table-column prop="project_name" sortable="custom" label="project_name"></el-table-column>
                <el-table-column prop="platform" sortable="custom" label="platform"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                        >编辑</el-button>
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="currentPage1"
                        :page-sizes="[50, 100, 150, 200]"
                        :page-size="pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" :visible.sync="editVisible" width="40%">
            <el-form ref="form" :model="form" label-width="120px">
                <el-form-item label="数据表">
                    <el-input value="admob_app" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="app_id">
                    <el-input v-model="form.app_id" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="project_name">
                    <el-input v-model="form.project_name"></el-input>
                </el-form-item>
                <el-form-item label="platform">
                    <el-input v-model="form.platform"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query2:'',
            query: {
                address: '',
                name: '',
                pageIndex: 1,
                pageSize: 10
            },
            pagesize:50,
            currentPage1: 1,
            total:0,
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1
        };
    },
    created() {
        this.getData();
    },
    methods: {
        handleSizeChange(val) {
            this.pagesize=val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        handleSizeChange(val) {
            this.pagesize=val;
        },
        handleCurrentChange(val) {
            this.currentPage1 = val;
        },
        // 获取 easy-mock 的模拟数据
        getData() {
            //发起get请求
        this.$http.post('script_data/submit/',
            {
            "table_name": "admob_app",
            },{
                headers:{
                    "Authorization":"JWT " + localStorage.getItem("jwt-token")
                }
                })
        .then(function(res){
          if(res.data.code==200)
          {
              this.tableData=res.data.list
              this.total=res.data.list.length
              this.pictLoading=false
              this.$nextTick(() => {
                            this.$refs.multipleTable.doLayout();
                            // el-table加ref="multipleTable"
                        });
          }
          }).catch(function(err){
                console.log(err);
            })

            /* this.$axios({
                method:'get',
                url:'http://192.168.1.29:8085/basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
            
      },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'pageIndex', 1);
            this.getData();
        },
        // 删除操作
        handleDelete(index, row) {
            let del_val = row.id;
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                            Vue.http.options.emulateJSON = true;
                            this.$http.post('script_data/delete/',
                        {
                            "database_table": "admob_app",
                            "id": del_val
                        },{
                            headers:{
                                "Authorization":"JWT " + localStorage.getItem("jwt-token")
                            }
                            })
                    .then(function(res){
                    if(res.data.code==200)
                    {
                    this.$message.success('删除成功');
                    this.tableData.splice(index, 1);
                        
                    }
                    }).catch(function(err){
                            alert(err);
                        })
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            Vue.http.options.emulateJSON = true;
            this.$http.post('script_data/edit/',
            {
                "database_table": "admob_app",
                "app_id": this.form.app_id,
                "project_name": this.form.project_name,
                "platform":this.form.platform
            },{
                headers:{
                    "Authorization":"JWT " + localStorage.getItem("jwt-token")
                }
                })
        .then(function(res){
          if(res.data.code==200)
          {
              this.editVisible = false;
              this.$message.success(`修改成功`);
            
          }
          }).catch(function(err){
                alert(err);
            })
            
        },
        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'pageIndex', val);
            this.getData();
        },
        
    // 自定义排序功能，用户排序的时候触发
    sortChange(v) {
   	//正序
      if (v.column.order == "ascending") {
        //通过属性showWeights进行排序
          this.tableData.sort(this.sortList(v.column.property));
       
      }
      //倒序
		 else if (v.column.order == "descending") {
          this.tableData.sort(this.sortListDesc(v.column.property));
      }
      },
 //通过数组对象的某个属性进行排序		
    sortList(property) {
      return function(a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return (value1<value2) ? -1 : ((value1>value2) ? 1 : 0);
      };
    },

    //通过数组对象的某个属性进行倒序排列
    sortListDesc(property) {
      return function(a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return (value1<value2) ? 1 : ((value1>value2) ? -1 : 0);
      };
    },
    search(query2){
        return this.tableData.filter(item=>{
            
                            var array = query2.split(" ");
                            if(array[1]==undefined) array[1]=''
                            if(array[2]==undefined) array[2]=''
                            if(array[3]==undefined) array[3]=''
                            if(array[4]==undefined) array[4]=''
                            
                            if(JSON.stringify(item).includes(array[0])&&JSON.stringify(item).includes(array[1])&&JSON.stringify(item).includes(array[2])&&JSON.stringify(item).includes(array[3])&&JSON.stringify(item).includes(array[4]))
                                return item
                    })
                    this.total=this.tableData.length
      },
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
