<template>
    <div>
        <div v-if="user_group.indexOf('测试组')==-1">
            <cw403></cw403>
        </div>
        <div v-if="user_group.indexOf('测试组')>-1">
        <div class="container">
            <el-button @click="editVisible=true" type="primary" style="margin-top:-20px;margin-bottom:20px;" plain icon="el-icon-circle-plus-outline">添加数据</el-button>
            <el-tabs type="border-card">
                <el-tab-pane label="app_id_to_project_name"><t1></t1></el-tab-pane>
                <el-tab-pane label="topon_app"><t2></t2></el-tab-pane>
                <el-tab-pane label="umeng_app"><t3></t3></el-tab-pane>
                <el-tab-pane label="token"><t4></t4></el-tab-pane>
                <el-tab-pane label="loongcheer_project_spendinfo"><t5></t5></el-tab-pane>
                <el-tab-pane label="admob_app"><t6></t6></el-tab-pane>
            </el-tabs>
           
        </div>
         <el-dialog title="添加值" :visible.sync="editVisible" width="40%">
            <el-form label-width="120px">
                <el-form-item label="数据表">
                    <el-select v-model="shuju" placeholder="请选择" style="width:100%" @change="change_database($event)">
                        <el-option
                        v-for="item in options"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                    <div id="_app_id_to_project_name" style="display:block">
                        <el-form-item label="app_name">
                            <el-input v-model="app_name"></el-input>
                        </el-form-item>
                        <el-form-item label="project_name">
                            <el-input v-model="project_name"></el-input>
                        </el-form-item>
                        <el-form-item label="platform">
                            <el-input v-model="platform"></el-input>
                        </el-form-item>
                    </div>
                    <div id="_topon_app" style="display:none">
                        <el-form-item label="app_id">
                            <el-input v-model="app_id"></el-input>
                        </el-form-item>
                        <el-form-item label="project_name">
                            <el-input v-model="project_name"></el-input>
                        </el-form-item>
                        <el-form-item label="platform">
                            <el-input v-model="platform"></el-input>
                        </el-form-item>
                    </div>
                    <div id="_umeng_app" style="display:none">
                        <el-form-item label="app_id">
                            <el-input v-model="app_id"></el-input>
                        </el-form-item>
                        <el-form-item label="project_name">
                            <el-input v-model="project_name"></el-input>
                        </el-form-item>
                        <el-form-item label="platform">
                            <el-input v-model="platform"></el-input>
                        </el-form-item>
                    </div>
                    <div id="_token" style="display:none">
                        <el-form-item label="token_name">
                            <el-input v-model="token_name"></el-input>
                        </el-form-item>
                        <el-form-item label="access_token">
                            <el-input v-model="access_token"></el-input>
                        </el-form-item>
                        <el-form-item label="refresh_token">
                            <el-input v-model="refresh_token"></el-input>
                        </el-form-item>
                    </div>
                    <div id="_loongcheer_project_spendinfo" style="display:none">
                        <el-form-item label="project_name">
                            <el-input v-model="project_name"></el-input>
                        </el-form-item>
                        <el-form-item label="ac_id">
                            <el-input v-model="ac_id"></el-input>
                        </el-form-item>
                        <el-form-item label="platform">
                            <el-input v-model="platform"></el-input>
                        </el-form-item>
                        <el-form-item label="recharge_agent">
                            <el-input v-model="recharge_agent"></el-input>
                        </el-form-item>
                    </div>
                    <div id="_admob_app" style="display:none">
                        <el-form-item label="app_id">
                            <el-input v-model="app_id"></el-input>
                        </el-form-item>
                        <el-form-item label="project_name">
                            <el-input v-model="project_name"></el-input>
                        </el-form-item>
                        <el-form-item label="platform">
                            <el-input v-model="platform"></el-input>
                        </el-form-item>
                    </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="queding">确 定</el-button>
            </span>
        </el-dialog>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { fetchData } from '../../api/index';
import t1 from './data-t1';
import t2 from './data-t2';
import t3 from './data-t3';
import t4 from './data-t4';
import t5 from './data-t5';
import t6 from './data-t6';
export default {
    name: 'basetable',
    components:{
        t1,
        t2,
        t3,
        t4,
        t5,
        t6,
    },
    data() {
        return {
            user_group:localStorage.getItem('user-group'),
            options:["app_id_to_project_name","topon_app","umeng_app","token","loongcheer_project_spendinfo","admob_app"],
            shuju:'',
            app_name:'',
            project_name:'',
            platform:'',
            app_id:'',
            name:'',
            access_token:'',
            refresh_token:'',
            ac_id:'',
            recharge_agent:'',
            query: {
                address: '',
                name: '',
                pageIndex: 1,
                pageSize: 10
            },
            editVisible: false,
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1
        };
    },
    created() {
    },
    methods: {
        // 根据选项改变表单内容
        change_database(e){
            if(e==='topon_app'){
                let five_id = document.getElementById('_admob_app');
                five_id.style.display='none';
                five_id.getElementsByTagName("INPUT").disabled = true;
                let first_id = document.getElementById('_app_id_to_project_name');
                first_id.style.display='none';
                first_id.getElementsByTagName("INPUT").disabled = true;
                let second_id = document.getElementById('_loongcheer_project_spendinfo');
                second_id.style.display='none';
                second_id.getElementsByTagName("INPUT").disabled = true;
                let third_id = document.getElementById('_token');
                third_id.style.display='none';
                third_id.getElementsByTagName("INPUT").disabled = true;
                let four_id = document.getElementById('_umeng_app');
                four_id.style.display='none';
                four_id.getElementsByTagName("INPUT").disabled = true;
                let database_id = document.getElementById('_topon_app');       
                database_id.style.display='block';
                database_id.getElementsByTagName("INPUT").disabled = false;
            }else if(e==='app_id_to_project_name'){
                let five_id = document.getElementById('_admob_app');
                five_id.style.display='none';
                five_id.getElementsByTagName("INPUT").disabled = true;
                let first_id = document.getElementById('_topon_app');
                first_id.style.display='none';
                first_id.getElementsByTagName("INPUT").disabled = true;
                let second_id = document.getElementById('_loongcheer_project_spendinfo');
                second_id.style.display='none';
                second_id.getElementsByTagName("INPUT").disabled = true;
                let third_id = document.getElementById('_token');
                third_id.style.display='none';
                third_id.getElementsByTagName("INPUT").disabled = true;
                let four_id = document.getElementById('_umeng_app');
                four_id.style.display='none';
                four_id.getElementsByTagName("INPUT").disabled = true;
                let database_id = document.getElementById('_app_id_to_project_name');       
                database_id.style.display='block';
                database_id.getElementsByTagName("INPUT").disabled = false;
            }else if(e==='umeng_app'){
                let five_id = document.getElementById('_admob_app');
                five_id.style.display='none';
                five_id.getElementsByTagName("INPUT").disabled = true;
                let first_id = document.getElementById('_topon_app');
                first_id.style.display='none';
                first_id.getElementsByTagName("INPUT").disabled = true;
                let second_id = document.getElementById('_loongcheer_project_spendinfo');
                second_id.style.display='none';
                second_id.getElementsByTagName("INPUT").disabled = true;
                let third_id = document.getElementById('_token');
                third_id.style.display='none';
                third_id.getElementsByTagName("INPUT").disabled = true;
                let four_id = document.getElementById('_app_id_to_project_name');
                four_id.style.display='none';
                four_id.getElementsByTagName("INPUT").disabled = true;
                let database_id = document.getElementById('_umeng_app');       
                database_id.style.display='block';
                database_id.getElementsByTagName("INPUT").disabled = false;
            }else if(e==='token'){
                let five_id = document.getElementById('_admob_app');
                five_id.style.display='none';
                five_id.getElementsByTagName("INPUT").disabled = true;
                let first_id = document.getElementById('_topon_app');
                first_id.style.display='none';
                first_id.getElementsByTagName("INPUT").disabled = true;
                let second_id = document.getElementById('_loongcheer_project_spendinfo');
                second_id.style.display='none';
                second_id.getElementsByTagName("INPUT").disabled = true;
                let third_id = document.getElementById('_umeng_app');
                third_id.style.display='none';
                third_id.getElementsByTagName("INPUT").disabled = true;
                let four_id = document.getElementById('_app_id_to_project_name');
                four_id.style.display='none';
                four_id.getElementsByTagName("INPUT").disabled = true;
                let database_id = document.getElementById('_token');       
                database_id.style.display='block';
                database_id.getElementsByTagName("INPUT").disabled = false;
            }else if(e==='loongcheer_project_spendinfo'){
                let five_id = document.getElementById('_admob_app');
                five_id.style.display='none';
                five_id.getElementsByTagName("INPUT").disabled = true;
                let first_id = document.getElementById('_topon_app');
                first_id.style.display='none';
                first_id.getElementsByTagName("INPUT").disabled = true;
                let second_id = document.getElementById('_token');
                second_id.style.display='none';
                second_id.getElementsByTagName("INPUT").disabled = true;
                let third_id = document.getElementById('_umeng_app');
                third_id.style.display='none';
                third_id.getElementsByTagName("INPUT").disabled = true;
                let four_id = document.getElementById('_app_id_to_project_name');
                four_id.style.display='none';
                four_id.getElementsByTagName("INPUT").disabled = true;
                let database_id = document.getElementById('_loongcheer_project_spendinfo');       
                database_id.style.display='block';
                database_id.getElementsByTagName("INPUT").disabled = false;
            }else if(e==='admob_app'){
                let five_id = document.getElementById('_loongcheer_project_spendinfo');
                five_id.style.display='none';
                five_id.getElementsByTagName("INPUT").disabled = true;
                let first_id = document.getElementById('_topon_app');
                first_id.style.display='none';
                first_id.getElementsByTagName("INPUT").disabled = true;
                let second_id = document.getElementById('_token');
                second_id.style.display='none';
                second_id.getElementsByTagName("INPUT").disabled = true;
                let third_id = document.getElementById('_umeng_app');
                third_id.style.display='none';
                third_id.getElementsByTagName("INPUT").disabled = true;
                let four_id = document.getElementById('_app_id_to_project_name');
                four_id.style.display='none';
                four_id.getElementsByTagName("INPUT").disabled = true;
                let database_id = document.getElementById('_admob_app');       
                database_id.style.display='block';
                database_id.getElementsByTagName("INPUT").disabled = false;
            }else{}
        },
        // 获取 easy-mock 的模拟数据
        getData() {
            
        },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, 'pageIndex', 1);
            this.getData();
        },
        // 删除操作
        handleDelete(index, row) {
            // 二次确认删除
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    this.$message.success('删除成功');
                    this.tableData.splice(index, 1);
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = '';
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + ' ';
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
        },
        queding(){
            Vue.http.options.emulateJSON = true;
            this.$http.post('script_data/add/',
            {
                "database_table": this.shuju,
                "app_name": this.app_name,
                "project_name": this.project_name,
                "platform": this.platform,
                "app_id": this.app_id,
                "refresh_token": this.refresh_token,
                "token_name": this.token_name,
                "ac_id": this.ac_id,
                "recharge_agent": this.recharge_agent,
                "access_token": this.access_token,
            },{
                headers:{
                    "Authorization":"JWT " + localStorage.getItem("jwt-token")
                }
                })
        .then(function(res){
          if(res.data.code==200)
          {
              this.editVisible = false;
              this.$message.success(`添加成功`);
            
          }
          }).catch(function(err){
                alert(err);
            })
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$message.success(`修改第 ${this.idx + 1} 行成功`);
            this.$set(this.tableData, this.idx, this.form);
        },
        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'pageIndex', val);
            this.getData();
        }
    }
};
</script>
<style>
.el-tabs__item{
    height: 70px;
    display: inline-flex;
    justify-content: center;
    min-width: 200px;
    align-items: center;
}
.el-tabs--border-card>.el-tabs__header .el-tabs__item+.el-tabs__item, .el-tabs--border-card>.el-tabs__header .el-tabs__item:first-child{
    border: 1px #8080800a solid;
}
</style>
<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
